<template>
  <div class="thanks-page-content text-center mx-auto">
        <div class="thanks-page-info">
      <div class="thank-you-text">
        <p class="font-weight-bold fs-24 mb-0">
          Thank you {{ vehicleInfo.customerName }} for your enquiry on AutoFlip.
        </p>
      </div>
      <div v-if="isShowValuation" class="estimated-value">
        <p class="mb-2 font-weight-bold title">ESTIMATED MARKET GUIDE</p>
        <div
          class="
            autoflip-price
            d-flex
            justify-content-center
            align-items-center
            font-weight-bold
          "
          :class="hasGotPrice ? '' : 'no-price'"
        >
          <span v-if="hasGotPrice" class="fs-20">
            {{ `${fromPrice} - ${toPrice}` }}*
          </span>
          <span v-else class="no-valuation">
            We can't provide an accurate price guidance right now
          </span>
        </div>
        <div class="market-guide-disclaimer">
          <p class="text-center">This is a guide only. Actual sale price may vary.  </p>
        </div>
        <div class="redbook-logo">
          <p class="text-center">Powered by</p>
          <img v-if="isMobile" :src="require('@/assets/images/logo_redbook_mobile.png')" />
          <img v-else :src="require('@/assets/images/logo_redbook_desktop.png')" />
        </div>
      </div>
      <div class="thanks-page-question">
        <p class="section-header font-weight-bold fs-24">What to expect next?</p>
        <ul class="desc">
          <li
            v-for="(item, index) in whatToExpectNextContent"
            :key="index"
            class="font-weight-thin"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
    <div class="body-content">
      <p
        class="section-header font-weight-bold fs-24 pt-2"
      >
        Your car details
      </p>
      <div class="bg-white main-content">
        <!-- <p class="mb-2 main-content-title">YOUR CAR INFORMATION</p> -->
        <div
          class="d-flex justify-content-center align-items-center item mx-auto"
        >
          <p class="mb-0">{{ vehicleInfo.year }} {{ vehicleInfo.make }} {{ vehicleInfo.model }}</p>
        </div>
        <vehicle-slide></vehicle-slide>
        <div class="vehicle mx-auto">
          <vehicle-condition :vehicleInfo="vehicleInfo"></vehicle-condition>
          <vehicle-detail :vehicleInfo="vehicleInfo"></vehicle-detail>
        </div>
        <div class="text-note px-2 mb-0">
          Something not quite right?<br />
          <span
            >No problem, simply email
            <a
              href="mailto:info@autoflip.com.au"
              style="color: inherit; text-decoration: underline;"
              >info@autoflip.com.au</a
            >
            and we'll update your info.</span
          >
        </div>
      </div>
    </div>
    <how-we-work></how-we-work>
    <div v-if="isShowValuation" class="pricing-disclaimer font-weight-thin">
      <p>
        * The price guide is created using independent market data. 
        It is indicative only and relies on the vehicle information provided at the time of submission. 
        Details such as vehicle specification, number of kilometres, and condition of vehicle all impact the price guide. 
        The final sale price may differ and will depend on market conditions and demand from our dealer network. 
        AutoFlip is not responsible for any errors provided or omissions of information.
      </p>
    </div>
  </div>
</template>

<script>
import VehicleSlide from "@/components/thanks-page/VehicleSlide";
import VehicleCondition from "@/components/thanks-page/VehicleCondition";
import VehicleDetail from "@/components/thanks-page/VehicleDetails";
import HowWeWork from "@/components/thanks-page/HowWeWork";

export default {
  name: "Index",
  data() {
    return {
      vehicleInfo: { ...this.$store.getters.curVehicleInfo },
      isMobile: true,
      valuation: {},
      isShowValuation: false,
    };
  },
  computed: {
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 768px)");
    },
    isNotSelling: function() {
      return this.vehicleInfo.sell === "I'm not selling";
    },
    fromPrice() {
      return "$" + new Intl.NumberFormat().format(this.valuation.from);
    },
    toPrice() {
      return "$" + new Intl.NumberFormat().format(this.valuation.to);
    },
    discretePrice() {
      return "$" + new Intl.NumberFormat().format(this.valuation.discreteValue);
    },
    hasGotPrice: function() {
      return this.valuation.from > 0 && this.valuation.to > 0;
    },
    whatToExpectNextContent: function() {
      return !this.isNotSelling
        ? [
            "Before your car is officially listed for sale, a member of our customer service team will contact you within the next business day.",
            "They'll confirm a few details, advise you on valuing your car, answer any questions and then go to work to get you the best price, fast.",
            "In the meantime, you can continue updating your details by clicking the link provided in the confirmation email you'll receive shortly.",
          ]
        : [
            "A friendly team member from AutoFlip may call you to check if they can assist with anything for your car.",
            "If you change your mind and decide to sell your car, continue updating your details by clicking the link provided in the confirmation email you'll receive shortly.",
          ];
    },
  },
  methods: {
    // goBack() {
    //   this.$router.push({name: 'vehicle-info'})
    // }
  },
  components: {
    VehicleSlide,
    VehicleCondition,
    VehicleDetail,
    HowWeWork,
  },
  mounted() {
    // Check mobile screen
    this.mobileMediaQuery.addListener(e => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
    this.valuation = JSON.parse(sessionStorage.getItem('valuation'))
    sessionStorage.removeItem('valuation')

    const valuationToggle = localStorage.getItem('isShowValuation');
    if (valuationToggle && valuationToggle === 'true') this.isShowValuation = true;
    localStorage.removeItem('isShowValuation');
  },
};
</script>

<style lang="scss">
.thanks-page-content {
  color: #404040;

  .thanks-page-info {
    background-color: #f9fafb;
    padding: 20px;
  }

  .estimated-value {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 30px;
    width: 366px;
    
    .title {
      font-size: 24px;
      line-height: 23px;
      color: #1F8252;
      margin-bottom: 15px !important;
    }
    .autoflip-price {
      font-family: Avenir Next LT W05 Demi;
      min-width: 340px;
      height: 71px;
      margin: 5px auto 5px;
      border-radius: 30px;
      border: 1px solid #414141;
      width: 90%;
    }
    .no-valuation {
      font-size: 16px;
      padding: 10px;
    }
    .btn-loading {
      font-size: 16px;
      width: 24px;
      height: 24px;
    }
    .market-guide-disclaimer {
      font-size: 12px;
      line-height: 24px;
    }

     .redbook-logo {
      p {
        margin-bottom: 0.5rem;
      }
      img {
        margin-bottom: 1rem;
      }
    }
  }
  .thanks-page-question {
    .section-header {
      color: #1f8252;
      text-transform: uppercase;
      margin: 1rem 0;
    }
    .desc {
      padding-inline-start: 35px;
      padding-right: 20px;
      li {
        text-align: left;
      }
    }
  }
  .body-content {
    margin-bottom: 30px;
    .section-header {
      color: #1f8252;
      text-transform: uppercase;
      margin: 1rem 0;
    }

    .main-content {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;

      .main-content-title {
        font-size: 24px;
        font-family: Avenir Next LT W05 Demi;
        font-weight: 400;
      }

      .item {
        width: fit-content;
        height: 40px;
        background-color: #f1f1f1;
        border-radius: 10px;
        padding: 0 12px;
      }

      .text-note {
        color: #74bd58;
        font-size: 14px;
        text-align: left;
        max-width: 354px;
        margin-top: 17px;
        margin-left: auto;
        margin-right: auto;

        span {
          color: black !important;
        }
      }
    }
  }
  .lean-info {
    padding-bottom: 30px;
    margin-bottom: 20px;
  }
  .pricing-disclaimer {
    text-align: left;
    padding: 5px 16px 30px;
    color: #989898;
    font-size: 10px;
    line-height: 16px;
    min-width: 365px;
    
    p {
      margin: unset;
    }
  }
}

@media screen and(min-width: 576px) {
  .body-content {
    max-width: 570px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .thanks-page-content {
    .thank-you-text p {
      margin: 0 auto;
      max-width: 800px;
    }
    .body-content {
      max-width: 720px;
    }
    .main-content {
      padding-bottom: 30px !important;
      .vehicle {
        max-width: 980px;
        display: flex;
        justify-content: space-between;
      }
      .text-note {
        max-width: 515px !important;
        margin-top: 17px !important;
        padding: 0 !important;
      }
      .item {
        padding: 0px 22px !important;
      }
    }
    .lean-info {
      margin: 0;
      width: 100%;
      max-width: unset;
      .title-info {
        padding-top: 20px;
      }
    }
    .pricing-disclaimer {
      padding: 10px 100px !important;
      
      p {
        width: 70% !important;
        margin: 0 auto !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .content-detail-tittle{
    font-size: 20px !important;
  }
  .main-content-title {
    font-size: 20px !important;
  }
  .car-note {
    text-align: left !important;
  }
  
  .autoflip-price {
    max-width: 303px !important;
    .no-valuation {
      font-size: 20px !important;
    }
  }
  
  .no-price {
    max-width:303px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .title-content {
    .top-head {
      margin-bottom: 0rem !important;
      font-size: 22px !important;
      padding-top: 12px !important;
    }
    .desc {
      margin-top: 2px !important;
      .bottom-head {
        font-size: 22px !important;
        margin-bottom: 10px !important;
      }
      .desc-content {
        font-size: 14px !important;
      }
    }
  }
}

@media screen and (max-width: 450px) and (orientation: portrait){
  .top-head {
    padding: 0 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .main-content {
    .vehicle-non-slide {
      max-width: 600px !important;
    }
    .vehicle-slide {
      max-width: 625px !important;
    }
    .vehicle {
      display: block !important;
      justify-content: unset !important;
      .vehicle-condition {
        margin: 0 auto !important;
        width: 100% !important;
        max-width: 515px !important;
        .vehicle-condition-wrapper {
          flex-direction: column !important;
          .vehicle-condition-item {
            margin: 0 auto !important;
            transform: scale(1.1);
          }
        }
      }
      .vehicle-detail {
        margin: 0 auto !important;
        width: 100% !important;
        max-width: 515px !important;
        margin-top: 50px !important;
      }
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .vehicle {
    .vehicle-condition {
      margin-left: 5px !important;
    }
    .vehicle-detail {
      margin-right: 5px !important;
    }
  }
}
@media screen and(min-width: 900px) {
  .thanks-page-content {
    .thanks-page-question {
      .desc {
        max-width: 620px;
        margin: 0 auto;
      }
    }
    .body-content {
      max-width: 960px !important;
    }
  }
}
@media screen and(min-width: 990px) {
  .thanks-page-content {
    .main-content {
      .text-note {
        margin-left: 0 !important;
      }
    }
  }
}
@media screen and(min-width: 1200px) {
  .thanks-page-content {
    padding: 0 10px;
    .thank-you-text p {
      font-size: 27px !important;
    }
    .thanks-page-question {
      .desc {
        max-width: 800px;
      }
    }
    .body-content {
      max-width: 1120px;
    }
    .lean-info .type-info {
      max-width: 1050px !important;
      margin: 0 auto;
    }
  }
}
@media screen and (min-width: 1024px) {
  .estimated-value {
    padding-right: 30px;
    
    p {
      margin-bottom: 1rem !important;
    }
    .no-valuation {
      text-align: center !important;
    }
    .redbook-logo {
      p {
        margin-bottom: 0.5rem !important;
      }
    }
  }
  .pricing-disclaimer {
    padding: 25px 150px !important;
    font-size: 14px !important;
    line-height: 21px !important;
    text-align: center !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .pricing-disclaimer {
    p {
      width: 80% !important;
    }
  }
}
</style>
