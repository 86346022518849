<template>
  <div v-if="vehicleLength > 0 && !sendPhotoLater" class="vehicle-slide mx-auto position-relative">
    <div class="bg-icon-prev slick-prev">
      <div class="d-flex justify-content-center align-items-center">
        <img class='icon-prev' v-bind:src="require(`@/assets/images/icons/ic_${prevIcon}.svg`)" @click="showPrev" />
      </div>
    </div>
    <div class="bg-icon-next slick-next">
      <div class="d-flex justify-content-center align-items-center">
        <img class='icon-next' v-bind:src="require(`@/assets/images/icons/ic_${nextIcon}.svg`)" @click="showNext" />
      </div>
    </div>
    <div v-if="isMobile">
      <vue-slick-carousel v-bind="settings" class="car-main-slide mx-auto" ref="slide">
        <div v-for="(vehicle,index) in vehicleSlides" :key="index" class="img"
            :style="{ backgroundImage: 'url(' + vehicle.url + ')' }">
        </div>
      </vue-slick-carousel>
    </div>
    <div v-else>
      <vue-slick-carousel v-if="vehicleLength >= 6" v-bind="settings"
                          class="car-main-slide mx-auto" ref="slide" @afterChange="s1Changed">
        <div v-for="(vehicle,index) in vehicleSlides" :key="index" class="img"
            :style="{ backgroundImage: 'url(' + vehicle.url + ')' }">
        </div>
      </vue-slick-carousel>
      <vue-slick-carousel v-else v-bind="settings" class="car-main-slide mx-auto" ref="slide" :asNavFor="s2">
        <div v-for="(vehicle,index) in vehicleSlides" :key="index" class="img"
            :style="{ backgroundImage: 'url(' + vehicle.url + ')' }">
        </div>
      </vue-slick-carousel>
      <vue-slick-carousel v-if="vehicleLength >= 6" :slidesToShow="5" v-bind="sub_settings"
                          class="car-slide" ref="s2" @afterChange="s2Changed">
        <div v-for="(vehicle,index) in vehicleSlides" :key="index" class="img"
            :style="{ backgroundImage: 'url(' + vehicle.url + ')' }" @click="s2Change(index)">
        </div>
      </vue-slick-carousel>
      <vue-slick-carousel v-else v-bind="sub_settings" :slidesToShow="vehicleLength"
                          class="car-slide" ref="s2" :asNavFor="s1">
        <div v-for="(vehicle,index) in vehicleSlides" :key="index" class="img"
            :style="{ backgroundImage: 'url(' + vehicle.url + ')' }">
        </div>
      </vue-slick-carousel>
    </div>
  </div>
  <div v-else class="vehicle-non-slide d-flex flex-column justify-content-center align-items-center mx-auto">
    <img src="@/assets/images/icons/ic_no_photo.svg" alt="No Photo" :style="isMobile && 'width: 30px; height: 30px'">
    <p class="vehicle-non-slide-detail font-weight-bold">No photos added.</p>
    <p class="vehicle-non-slide-desc font-weight-thin mt-1">
    To finish listing your car, please add photos through the link in your confirmation email.
    </p>
    <p class="vehicle-non-slide-desc font-weight-thin mb-0">
      <a href="https://sell.autoflip.com.au/photo-guide" target="_blank" style="color: inherit; text-decoration: underline;">Read our tips</a>
    on how to capture the best photos.</p>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "VehicleSlide",
  data() {
    return {
      vehicleSlides: {...this.$store.getters.uploadImage},
      sendPhotoLater: this.$store.getters.sendPhotoLater,
      settings: {
        "dots": false,
        "arrows": false,
        "focusOnSelect": true,
        "infinite": true,
        "speed": 1000,
        "slidesToShow": 1,
        "slidesToScroll": 1
      },
      sub_settings: {
        "dots": false,
        "arrows": false,
        "focusOnSelect": true,
        "infinite": true,
        "initialSlide": 1,
        "swipe": false,
        "speed": 1000,
        "slidesToScroll": 1
      },
      vehicleLength: 0,
      s1: undefined,
      s2: undefined,
      s1Current: 0,
      s2Click: true,
      isMobile: true,
    }
  },
  computed: {
    mobileMediaQuery: function() { 
      return window.matchMedia('(min-width: 768px)');
    },
    nextIcon: function() {
      return this.isMobile ? 'next_single_default' : 'next_single_desktop';
    },
    prevIcon: function() {
      return this.isMobile ? 'prev_default' : 'prev_desktop';
    },
  },
  methods: {
    showPrev() {
      this.$refs.slide.prev()
    },
    showNext() {
      this.$refs.slide.next()
    },
    s1Changed(event) {
      this.s1Current = event
      this.$refs.s2.goTo(event + 1)
    },
    s2Change(event) {
      this.s2Click = false

      if (event == this.s1Current + 1) {
        this.$refs.slide.goTo(event)
        this.s2Click = true
      }
    },
    s2Changed(event) {
      if (!this.s2Click) {
        this.$refs.slide.goTo(event)
        this.s2Click = true
      }
    }
  },
  components: {
    VueSlickCarousel
  },
  mounted() {
    this.vehicleLength = Object.keys(this.vehicleSlides).length;

    this.$nextTick(() => {
      if (this.vehicleLength > 0 && this.vehicleLength <= 6) {
        this.s1 = this.$refs.slide;
        this.s2 = this.$refs.s2;
      }
    })

    // Check mobile screen
    this.mobileMediaQuery.addListener(e => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
  },
}
</script>

<style lang="scss">
.vehicle-slide {
  max-width: 364px;
  max-height: 192px;
  margin-top: 30px;
  margin-bottom: 40px;

  .car-main-slide {
    max-width: 364px;

    div {
      height: 192px;
    }

    .img {
      max-width: 354px;
      background-color: #F1F1F1;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 10px;
    }
  }

  .bg-icon-prev, .bg-icon-next {
    width: 20px;
    height: 40px;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.3);

    div {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);
    }

    &:before {
      content: none;
    }

    .icon-prev, .icon-next {
      width: 10px;
      height: 19px;
    }
  }

  .bg-icon-prev {
    left: 5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    div {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .bg-icon-next {
    right: 5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    div {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}

.vehicle-non-slide {
  position: relative;
  max-width: 354px;
  height: 204px;
  margin-top: 30px;
  margin-bottom: 40px;
  background-color: #EFEFEF;
  border-radius: 20px;

  .vehicle-non-slide-detail {
    color: #05B36D;
    font-size: 16px;
    line-height: 16.8px;
    margin-top: 15px;
  }

  .vehicle-non-slide-desc {
    color: black;
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) {
  .vehicle-slide {
    max-width: 980px !important;
    max-height: 470px !important;
    margin-top: 18.6px !important;
    margin-bottom: 80px !important;

    .car-main-slide {
      max-width: 820px !important;

      div {
        height: 434px !important;
      }

      .img {
        max-width: 800px !important;
        border-radius: 20px !important;
      }
    }

    .bg-icon-prev, .bg-icon-next {
      width: 21.21px !important;
      height: 42.43px !important;

      div {
        border-radius: 0 !important;
      }

      .icon-prev, .icon-next {
        width: 100%;
        height: 100%;
      }
    }

    .bg-icon-prev {
      border-radius: 0 !important;
    }

    .bg-icon-next {
      border-radius: 0 !important;
    }

    .car-slide {
      width: 100%;
      max-width: 590px;
      top: -36px;
      left: 50%;
      transform: translate(-50%, 0);

      .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .slick-slide {
        max-width: 118px;
      }

      div {
        height: 72px;
      }

      .img {
        max-width: 108px;
        background-color: #F1F1F1;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 5px;
      }
    }
  }

  .vehicle-non-slide {
    max-width: 800px !important;
    height: 434px !important;
    margin-top: 18.6px !important;
    margin-bottom: 60px !important;

    .vehicle-non-slide-detail {
      font-size: 24px !important;
      line-height: 28px !important;
      margin-top: 35px !important;
    }

    .vehicle-non-slide-desc {
      font-size: inherit !important;
      line-height: 20px;

      &:first-child {
        margin-top: 16px !important;
      }
    }
  }
}
</style>
