<template>
  <div class="lean-info text-center mt-2">
    <p class="title-info font-weight-bold text-center">
      <b class="easy-step-txt-black">How we work</b>
    </p>
    <div class="type-info">
      <div class="item mt-32 d-flex align-items-center">
        <div v-if="!isMobile" class="img-section">
          <img src="@/assets/images/easy-steps/Contacts.svg.png" class="img" />
        </div>
        <div class="step-section">
          <span class="step-item">1</span>
        </div>
        <div class="text-section ml-20">
          <p class="title-item mb-0 font-weight-bold">
            <b>List your car</b>
          </p>
          <p class="desc-item">
            A friendly team member is available for support and will contact you
            before listing.
          </p>
        </div>
      </div>
      <div class="item mt-32 d-flex align-items-center">
        <div v-if="!isMobile" class="img-section">
          <img src="@/assets/images/easy-steps/Car.svg.png" class="img" />
        </div>
        <div class="step-section">
          <span class="step-item">2</span>
        </div>
        <div class="text-section ml-20">
          <p class="title-item mb-0 font-weight-bold">
            <b>Receive your best offer</b>
          </p>
          <p class="desc-item">
            Get a competitive trade-in offer for your car from our large
            dealer buyer network, ready to buy now.
          </p>
        </div>
      </div>
      <div class="item mt-32 d-flex align-items-center">
        <div v-if="!isMobile" class="img-section">
          <img src="@/assets/images/easy-steps/News.svg.png" class="img" />
        </div>
        <div class="step-section">
          <span class="step-item">3</span>
        </div>
        <div class="text-section ml-20">
          <p class="title-item mb-0 font-weight-bold">
            <b>Get paid fast</b>
          </p>
          <p class="desc-item">
            Pay the
            <a :href="`${autoflipUrl}/seller-fees`" target="_blank"
              >success fee</a
            >, connect with your successful buyer and get paid quickly.
          </p>
        </div>
      </div>
    </div>
    <a
      href="https://www.autoflip.com.au/how-it-works"
      target="_blank"
      class="text-white"
    >
      <button class="btn-sell">
        <span class="font-weight-regular">Learn more</span>
      </button>
    </a>
  </div>
</template>

<script>
import { AUTOFLIP_URL } from "@/helper/constant";
export default {
  name: "HowWeWork",
  data() {
    return {
      autoflipUrl: AUTOFLIP_URL,
      isMobile: true,
    };
  },
  computed: {
    mobileMediaQuery: function () {
      return window.matchMedia("(min-width: 1200px)");
    },
  },
  mounted() {
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
    this.isMobile = !this.mobileMediaQuery.matches;
  },
};
</script>

<style lang="scss">
.lean-info {
  background-color: #ffffff;
  padding: 61px 0 50px;
  max-width: 100%;
  margin: 0 auto;
  background-color: #f9fafb;
  .title-info {
    line-height: 28px;
    font-size: 26px;
    .easy-step-txt {
      color: #2bb673;
    }
    .easy-step-txt-black {
      color: #414141;
    }
    .easy-step-txt-grey {
      font-size: 16px !important;
      color: #6f6f6f;
    }
  }
  .ml-20 {
    margin-left: 20px;
  }
  .ml-32 {
    margin-top: 32px;
  }
  .type-info {
    .item {
      padding: 0 27px 0 35px;
    }
    .text-section {
      text-align: left;
      .title-item {
        line-height: 28px;
        margin-top: 10px;
        font-size: 20px;
        @media screen and (max-width: 320px) {
          font-size: 14px;
        }
      }
      .desc-item {
        line-height: 24px;
        margin: 3px 0 0 0;
        font-size: 16px;
        font-family: "Avenir Next LT W05 Regular";
        color: #6f6f6f;
        @media screen and (max-width: 320px) {
          font-size: 12px;
        }
        a {
          text-decoration: underline;
          color: #1f8252;
        }
      }
    }
    .img-section {
      .img {
        width: 224px;
        height: 125.94px;
        @media screen and (max-width: 375px) {
          width: 204px;
          height: 105.94px;
        }
        @media screen and (max-width: 320px) {
          width: 184px;
          height: 85.94px;
        }
      }
    }
    .step-section {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #1f8252;
      border-radius: 9999px;
      margin-top: 10px;
      .step-item {
        font-family: "Avenir Next LT W05 Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }
  .btn-sell {
    width: 128px;
    height: 46px;
    background: #1f8252;
    border-radius: 6px !important;
    border: none;
    outline: none;
    color: white;
    font-size: 15px;
    &:hover {
      background: #22945d;
    }
  }
}
@media screen and(min-width: 768px) {
  .lean-info {
    width: 90%;
    max-width: 1050px;
    max-height: 100vh;
    padding: 0 20px 30px;
    margin-bottom: 48px;
    .title-info {
      font-size: 36px !important;
      line-height: 36px !important;
      margin: 40px 0;
    }
    .type-info {
      display: flex;
      justify-content: space-between;
      .item {
        width: 23%;
        position: relative;
        display: flex;
        flex-direction: column !important;
        align-items: center;
        padding: 0;
        .desc-item {
          max-width: 200px;
          margin: 10px auto 0;
        }
        &:nth-child(2) {
          .desc-item {
            max-width: 250px;
          }
        }
      }
      .text-section {
        text-align: center;
        margin-left: 0 !important;
        .title-item {
          font-size: 20px !important;
        }
      }
      .img-section {
        .img {
          width: 224px;
          height: 125.94px;
          margin-left: 0 !important;
        }
      }
    }
    .btn-sell {
      margin-top: 35px;
    }
  }
}
@media screen and(max-width: 768px) {
  .type-info {
    .item {
      display: flex;
      flex-direction: column !important;
      align-items: center;
      margin-bottom: 40px;
    }
    .text-section {
      text-align: center;
      margin-left: 0 !important;
      .title-item {
        text-align: center;
      }
      .desc-item {
        text-align: center;
      }
    }
  }
}
</style>