<template>
  <div class="vehicle-condition text-center mx-auto">
    <p class="fs-20 vehicle-condition-title"><b>Car condition</b></p>
    <div class="d-flex justify-content-center align-items-center">
      <div class="vehicle-condition-key">
        <img src="@/assets/images/icons/ic_service.svg" />
        <span>{{
          vehicleInfo.key === '1' ? vehicleInfo.key + ' key' : vehicleInfo.key + ' keys'
        }}</span>
      </div>
      <div class="vehicle-condition-logbook">
        <img src="@/assets/images/icons/ic_logbook.svg" />
        <span>{{
          vehicleInfo.ownerManual === "Yes" ? "Owner's manual" : "No owner's manual"
        }}</span>
      </div>
      <div class="vehicle-condition-logbook">
        <img src="@/assets/images/icons/ic_history.svg" />
        <span>{{
          vehicleInfo.serviceHistory === "None" ? "No service history" : vehicleInfo.serviceHistory + ' service history'
        }}</span>
      </div>
    </div>
    <p class="fs-14 mt-2 mb-3 location">
      <b
        >Location<span class="location-label"></span
        >{{ vehicleInfo.location }}</b
      >
    </p>
    <div v-if="vehicleInfo.description">
      <p class="fs-16 mb-1 mt-2"><b>Car description</b></p>
      <p class="font-weight-thin text-left fs-12 mb-0 px-2">
        {{ !expanded.carDescription ? splittedParagraph(vehicleInfo.description) : vehicleInfo.description }}
        <span 
          v-if="vehicleInfo.description.length > maxLength && !expanded.carDescription" 
          @click="expandedParagraph('carDescription')" 
          class="font-weight-bold more-text">
          <u>more</u>
        </span>
      </p>
    </div>
    <div class="vehicle-condition-wrapper">
      <div class="vehicle-condition-item mx-auto">
        <p class="fs-14 mb-0 service"><b>Exterior condition</b></p>
        <b-form-rating
          class="star-rating border-0 w-15 px-0"
          size="lg"
          :value="vehicleInfo.exterior"
          readonly
        />
        <div class="text-left d-flex vehicle-condition-rate-label">
          <span
            :class="[vehicleInfo.exterior === item.val ? 'visible' : 'invisible']"
            v-for="item in rate"
            :key="item.val"
            >{{ item.text }}</span
          >
        </div>
      </div>
      <div class="vehicle-condition-item mx-auto">
        <p class="fs-14 mb-0 log-book"><b>Interior condition</b></p>
        <b-form-rating
          class="star-rating border-0 w-15 px-0"
          size="lg"
          :value="vehicleInfo.interior"
          readonly
        />
        <div class="text-left d-flex vehicle-condition-rate-label">
          <span
            :class="[vehicleInfo.interior === item.val ? 'visible' : 'invisible']"
            v-for="item in rate"
            :key="item.val"
            >{{ item.text }}</span
          >
        </div>
      </div>
    </div>
    <div class="mb-3" v-if="vehicleInfo.conditionDescription">
      <p class="fs-16 mb-1 mt-1"><b>Condition description</b></p>
      <p class="font-weight-thin fs-12 text-left mb-0 px-2">
        {{ !expanded.conditionDescription ? splittedParagraph(vehicleInfo.conditionDescription) : vehicleInfo.conditionDescription }}
        <span 
          v-if="vehicleInfo.conditionDescription.length > maxLength && !expanded.conditionDescription" 
          @click="expandedParagraph('conditionDescription')" 
          class="font-weight-bold">
          <u>more</u>
        </span>
      </p>
    </div>
    <div class="key-info-wrapper">
      <div v-for="(vehicleCondition, index) in filteredVehicleConditions" :key="index" class="key-info-item">
        <p class="key-info-title font-weight-thin">{{ vehicleCondition.label }}:</p>
        <p class="key-info-value">{{vehicleInfo[vehicleCondition.key]}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { VEHICLE_CONDITIONS } from "@/config/constants";
import mixin from "@/mixins/common";

export default {
  name: "VehicleCondition",
  mixins: [mixin],
  props: ["vehicleInfo"],
  data() {
    return {
      rate: [
        { val: 1, text: "Poor" },
        { val: 2, text: "Average" },
        { val: 3, text: "Good" },
        { val: 4, text: "Very good" },
        { val: 5, text: "Excellent" },
      ],
      maxLength: 180,
      expanded: {
        carDescription: false,
        conditionDescription: false,
      },
      vehicleConditions: [...VEHICLE_CONDITIONS]
    };
  },
  computed: {
    filteredVehicleConditions: function() {
      return this.vehicleConditions.filter(item => this.vehicleInfo[item.key] !== "");
    },
  },
  methods: {
    expandedParagraph(type) {
      this.expanded[type] = true;
    },
    splittedParagraph: function(data) {
      const splitted = `${data.slice(0, 180)}...`;
      return data.length > this.maxLength
        ? splitted
        : data
    }
  },
};
</script>

<style lang="scss">
.vehicle-condition {
  max-width: 354px;
  min-height: 446px;
  padding: 16px 6px 0;
  border: 1px solid #c9c9c9;
  border-radius: 20px;

  .vehicle-condition-title {
    margin-bottom: 25px;
  }

  .vehicle-condition-key,
  .vehicle-condition-logbook {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 140px;
    height: 120px;
    margin: 0 5px;
    background-color: #f1f1f1;
    border-radius: 9.7px;

    img {
      width: 50px;
      height: 50px;
      margin: 18px auto 10px;
    }

    span {
      font-size: 12px;
    }
  }

  .vehicle-condition-item {
    width: 164px;
    padding-top: 25px;

    .star-rating {
      height: fit-content;
      padding: 0;
      margin-top: 6px;

      &:focus {
        box-shadow: none;
      }

      span.b-rating-star {
        padding: 0 4px;

        &:first-child {
          padding-left: 0;
        }

        svg {
          width: 26px;
          height: 25px;
        }
      }

      .b-rating-star-full {
        color: #00c44b;
      }
    }

    .vehicle-condition-rate-label {
      margin-top: 6px;

      span {
        display: inline-block;
        color: #989898;
        font-size: 10px;
        text-align: center;
        min-width: 34px;
        margin: 0 auto;

        &:first-of-type {
          min-width: 26px;
          margin-right: 4px;
        }
      }
    }
  }

  .location {
    .location-label {
      margin: 0 6px;
      border-left: 2px solid #404040;
    }
  }

  .key-info-wrapper {
    margin-bottom: 1rem;
    .key-info-item {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      font-size: 14px;
      border-top: 1px solid #E6E6E6;
      &:last-child {
        border-bottom: 1px solid #E6E6E6;
      }
      p {
        margin-bottom: 0;
      }
      .key-info-value {
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .vehicle-condition-item .form-control-lg {
    font-size: 1.6rem !important;
  }
}

@media screen and (min-width: 768px) {
  .vehicle-condition {
    max-width: 475px !important;
    width: calc(50% - 15px) !important;
    min-height: 100% !important;
    padding: 16px 32px 0 !important;
    margin: 0 !important;

    .vehicle-condition-wrapper {
      display: flex;
      justify-content: space-between;

      .vehicle-condition-item {
        margin: 0 !important;
      }
    }

    .location {
      margin-top: 10px;
    }
  }

  .more-text {
    cursor: pointer;
  }
}
</style>
